import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_banner_container = _resolveComponent("as-banner-container")!

  return (_openBlock(), _createBlock(_component_as_banner_container, { class: "asBannersTop" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBanners, (banner, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          banner
            ? (_openBlock(), _createBlock(_component_as_banner, {
                key: `banner${_ctx.placement}${i}`,
                banner: banner,
                platform: _ctx.platform,
                position: _ctx.position,
                placement: _ctx.placement,
                class: _normalizeClass(`asBannersTop_banner -${_ctx.placement} asBannersTop_bannerCount_${_ctx.bannersCount} -${_ctx.platform}${_ctx.placement}`),
                "in-day": _ctx.inDay,
                width: _ctx.bannerWidth
              }, null, 8, ["banner", "platform", "position", "placement", "class", "in-day", "width"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _: 1
  }))
}