
import { defineComponent, computed } from 'vue'
import useBanner from '@web/composition/useBanner'

export default defineComponent({
  name: 'AsBanner',
  props: {
    banner: {
      type: Object as () => Banner,
      required: true
    },
    platform: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: ''
    },
    inDay: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
    }
  },
  setup (props) {
    const {
      getImage,
      ...data
    } = useBanner({
      banner: props.banner,
      position: `${props.platform}_${props.position}_${props.placement}`,
      width: props.width
    })

    const showBanner = computed(() => getImage(props.banner) && props.banner)
    const showText = computed(() => props.inDay && props.banner.text)

    return {
      getImage,
      showBanner,
      showText,
      ...data
    }
  }
})
